.extraButtonClass {
  font-size: 14px !important;
  pointer-events: auto !important;
}
.extraButtonClass:hover {
  font-size: 14px !important;
  pointer-events: auto !important;
  visibility: visible !important;
  opacity: 1 !important;
}
.myCustomButtonClass {
  background: none;
  margin-top: 2px;
  margin-left: -3px;
  height: 1px;
  width: 2px;
  border: none;
  color: black;
}